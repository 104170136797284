import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    UpdatePasswordFormTypes,
    useApiUrl,
    useTranslate,
    useUpdatePassword,
} from "@refinedev/core";
import { ThemedTitleV2 } from "../../../layout/title";
import {
    Form,
    Input,
    Button,
    Card,
    Typography,
    Layout,
    Row,
    Col,
    theme,
} from "antd";
import { notification } from "antd";
import { titleStyles } from "./styles";
export const UpdatePasswordPage: React.FC = () => {
    const [form] = Form.useForm<UpdatePasswordFormTypes>();
    const t = useTranslate();
    const navigate = useNavigate();
    const { key } = useParams<{ key: string }>();
    const { mutate: updatePassword, isLoading } = useUpdatePassword();

    const onFinish = async (values: UpdatePasswordFormTypes) => {
        if (values.password !== values.confirmPassword) {
            notification.error({
                message: t(
                    "pages.updatePassword.errors.confirmPasswordNotMatch",
                    "Passwords do not match"
                ),
            });
            return;
        }
        updatePassword(
            { key, password: values.password },
            {
                onSuccess: () => {
                    notification.success({
                        message: t(
                            "pages.updatePassword.success",
                            "Password updated successfully"
                        ),
                    });
                    navigate("/login");
                },
                onError: (error) => {
                    notification.error({
                        message: t(
                            "pages.updatePassword.error",
                            "Error updating password"
                        ),
                        description: error.detail,
                    });
                },
            }
        );
    };

    const { token } = theme.useToken();

    const apiUrl = useApiUrl();
    fetch(`${apiUrl}/csrf-token`, {
        method: "GET",
        credentials: "include",
    });

    const PageTitle = (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "32px",
                fontSize: "20px",
            }}
        >
            <ThemedTitleV2 collapsed={false} />
        </div>
    );

    const CardTitle = (
        <Typography.Title
            level={3}
            style={{
                color: token.colorPrimaryTextHover,
                ...titleStyles,
            }}
        >
            {t("pages.updatePassword.title", "Set New Password")}
        </Typography.Title>
    );

    return (
        <Layout className="layout min-h-screen min-w-full flex justify-center items-center">
            <Row justify="center" align="middle" className="py-16 w-full">
                <Col xs={22} sm={18} md={12} lg={8} xl={6}>
                    <div className="flex justify-center mb-2">{PageTitle}</div>
                    <Card title={CardTitle} className="shadow-md">
                        <Form<UpdatePasswordFormTypes>
                            form={form}
                            layout="vertical"
                            onFinish={onFinish}
                            requiredMark={false}
                        >
                            <Form.Item
                                name="password"
                                label={t(
                                    "pages.updatePassword.fields.password",
                                    "New Password"
                                )}
                                rules={[
                                    { required: true },
                                    {
                                        min: 6,
                                        message: t(
                                            "pages.updatePassword.errors.passwordTooShort",
                                            "Password must be at least 6 characters long"
                                        ),
                                    },
                                ]}
                                className="mb-4"
                            >
                                <Input.Password
                                    className="w-full"
                                    placeholder="●●●●●●●●"
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item
                                name="confirmPassword"
                                label={t(
                                    "pages.updatePassword.fields.confirmPassword",
                                    "Confirm New Password"
                                )}
                                dependencies={["password"]}
                                rules={[
                                    { required: true },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue("password") ===
                                                    value
                                            ) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    t(
                                                        "pages.updatePassword.errors.confirmPasswordNotMatch",
                                                        "Passwords do not match"
                                                    )
                                                )
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className="w-full"
                                    placeholder="●●●●●●●●"
                                    size="large"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    size="large"
                                    htmlType="submit"
                                    loading={isLoading}
                                    block
                                >
                                    {t(
                                        "pages.updatePassword.buttons.submit",
                                        "Update"
                                    )}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};
